<template>
  <div id="app">
    <div id="page-top"></div>
    <MenuBar v-if="$mq == 'lg' || $mq == 'xl'"></MenuBar>
    <SmallDeviceMenuBar v-else></SmallDeviceMenuBar>
    <button
      class="back-button btn btn-primary"
      v-if="($mq == 'lg' || $mq == 'xl') && displayBackButton"
      @click="$router.go(-1)"
    >
      >
    </button>

    <router-view></router-view>
    <TheFooter
      v-if="$route.path !== '/company' && $route.path !== '/pipeline'"
    ></TheFooter>
  </div>
</template>

<script>
import MenuBar from "./components/MenuBar";
import SmallDeviceMenuBar from "./components/SmallDeviceMenuBar";
import TheFooter from "./components/TheFooter";

export default {
  components: {
    MenuBar,
    SmallDeviceMenuBar,
    TheFooter,
  },
  computed: {
    displayBackButton() {
      return this.$route.path !== "/";
    },
  },
  created() {
    this.$store.dispatch("GET_TECHNOLOGY_SECTION_CONTENT");
    this.$store.dispatch("GET_COMPANY_SECTION_CONTENT");
    this.$store.dispatch("GET_PIPELINE_SECTION_CONTENT");
    this.$store.dispatch("GET_PIPELINE_ITEMS");
    this.$store.dispatch("GET_TEAMS");
    this.$store.dispatch("GET_NEWS_ARTICLES");
    this.$store.dispatch("GET_PUBLICATIONS");
  },
};
</script>

<style lang="scss">
@import "assets/styles/variables.scss";
@import "assets/styles/global.scss";

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;500&display=swap");

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

#app {
  width: 100%;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

button {
  outline: none !important;
}

input[type="button"]::-moz-focus-inner {
  border: 0 !important;
}
.back-button {
  position: fixed;
  z-index: 2000;
  top: $navbar-height;
  margin-top: 1em;
  right: 0;
  margin-right: 1em;
  opacity: 0.8;
}
.slide-from-top-enter-active,
.slide-from-top-enter-active,
.slide-from-bottom-enter-active,
.slide-from-bottom-leave-active {
  transition: all 5s;
}
.slide-from-bottom-enter,
.slide-from-bottom-leave-to {
  opacity: 100%;
}
.slide-from-top-enter,
.slide-from-top-leave-to {
  opacity: 0;
}
</style>
