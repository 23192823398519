<template>
  <nav id="mainNav" class="fixed-top" :class="solidNav ? 'scrolled' : ''">
    <div id="logo-container">
      <router-link to="/#page-top">
        <img src="../assets/logo/inverted.png" id="logo" alt />
      </router-link>
    </div>

    <ul class="nav-items">
      <li class="nav-item">
        <router-link to="/#technology" class="nav-link">{{
          $t("technology")
        }}</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/#pipeline" class="nav-link">{{
          $t("pipeline")
        }}</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/#company" class="nav-link">{{
          $t("company")
        }}</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/#newssection" class="nav-link">
          {{ $t("news") }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/#contact">Contact</router-link>
      </li>
      <li class="nav-item lang">
        <a
          class="nav-link"
          @click="setLocale('fr')"
          :class="$root.$i18n.locale == 'fr' ? 'text-secondary' : ''"
          >Fr</a
        >
        <div style="color: rgba(255, 255, 255, 0.7)">|</div>
        <a
          class="nav-link"
          @click="setLocale('en')"
          :class="$root.$i18n.locale == 'en' ? 'text-secondary' : ''"
          >En</a
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data: () => {
    return {
      solidNav: false,
    };
  },
  methods: {
    handleScroll(event) {
      if (window.scrollY > 50 || this.$route.path !== "/") {
        this.solidNav = true;
      } else {
        this.solidNav = false;
      }

      return event;
    },
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<i18n>
{
  "en" : {
    "company" : "Company",
    "technology": "Technology",
    "news" : "News",
    "pipeline" : "Pipeline"
  },
  "fr" : {
    "company" : "Entreprise",
    "technology" : "Technologie",
    "news" : "Actualités",
    "pipeline" : "Pipeline"
  }
}
</i18n>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/global.scss";

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bar {
  display: flex;
}
// Base styling for the navbar
#mainNav {
  // box-shadow: $box-shadow;

  font-family: "Comfortaa", cursive;

  opacity: 0;
  animation: fade-in 1.5s ease-in forwards;
  animation-delay: 1s;
  padding: 20px 0;
  background-color: transparent;
  transition: background-color 0.4s ease;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  height: 100px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // align-items: center;
  align-items: center;

  .nav-items {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: 0;
    .nav-link {
      // @include display-font;
      font-size: 0.9rem;
      padding: 0;
      color: white;
      &:hover,
      &:active {
        color: $secondary;
      }
      &.active {
        color: $primary !important;
      }
    }
  }
  .lang {
    display: flex;
    flex-direction: row;
    width: 70px;
    justify-content: space-between;
  }

  #logo-container {
    width: 10%;
    height: 100%;
    align-content: center;
    justify-content: center;
    // padding: auto;
    // background-color: #fff;
  }

  #logo {
    cursor: pointer;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    padding: 0;
    // margin-bottom: 90px;
    width: 150%;
    // transform: translate(0, 60%);
    // transform: scale(1.5);
  }

  &.scrolled {
    height: $navbar-height;
    box-shadow: red($color: #000000);
    background-color: $primary-transparent;

    #logo {
      // -webkit-transform: scale(0.5);
      // transform: scale(1);
      width: 100%;
    }
  }
}
</style>
