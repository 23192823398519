<template>
  <router-link :to="`#${item.page}`" class="link-wrapper">
    <div class="main text-center" @mouseover="hovered = true" @mouseleave="hovered = false">
      <h1
        class="container text-white"
        :class="hovered ? 'text-secondary' : ''"
      >{{ item.title[$i18n.locale] }}</h1>
      <img :src="require('../assets/icons/' + item.icon)" :style="hovered ? 'hovered' : ''" />
      <div class="text" style>{{ item.text[$i18n.locale] }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      hovered: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.hover {
  color: blue;
}
.link-wrapper {
  text-decoration: none;
  margin: 0;
}
.main {
  width: 100%;
  margin: 0 !important;
}
img {
  width: 60px;
  margin-bottom: 5%;
  &.hovered {
    width: 80px;
    margin-bottom: 0px;
  }
}
p {
  width: 80%;
}
.text {
  color: white;
  margin: auto;
  width: 80%;
}
h1 {
  font-size: 1.5em;
}
</style>
