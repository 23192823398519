
<template>
  <div class="main">
    <div v-if="legalNoticesContent">
      <div v-html="legalNoticesContent.elements.content_fr.value"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  created() {
    this.$store.dispatch("GET_LEGAL_NOTICES_CONTENT");
  },
  computed: {
    ...mapGetters(["legalNoticesContent"])
  }
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  margin-top: 90px;

  padding: 0 16px;
  @media (min-width: 600px) {
    padding: 0 20%;
    padding-top: 24px;
  }
  h2 {
    font-size: 30px;
  }
}
</style>