import Vue from "vue";
import Router from "vue-router";

import MainScroll from "./components/MainScroll";
import Pipeline from "./components/pages/Pipeline";
import Team from "./components/pages/Team";
import Technology from "./components/pages/Technology";
import Legal from "./components/pages/Legal";

Vue.use(Router);

const VueScrollTo = require("vue-scrollto");

const scrollBehavior = function(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    const position = {};
    if (to.hash) {
      VueScrollTo.scrollTo(to.hash, 500);
      if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
        return position;
      }
    }
    return new Promise((resolve) => {
      resolve({ x: 0, y: 0 });
    });
  }
};

let router = new Router({
  routes: [
    {
      name: "welcomePage",
      path: "/",
      component: MainScroll,
    },
    {
      name: "pipelinePage",
      path: "/pipeline",
      component: Pipeline,
      meta: { scrollToTop: true },
    },
    {
      name: "companyPage",
      path: "/company",
      component: Team,
      meta: { scrollToTop: true },
    },
    {
      name: "technologyPage",
      path: "/technology",
      component: Technology,
      meta: { scrollToTop: true },
    },
    {
      name: "LegalNotices",
      path: "/legal",
      component: Legal,
      meta: { scrollToTop: true },
    },
  ],
  scrollBehavior,
});

export default router;
