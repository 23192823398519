const objectify = function (personsArray) {
  // converting the list into an object
  return personsArray.reduce((obj, item) => {
    obj[item.system.codename] = item;
    return obj;
  }, {});
};

export let getTeams = async function (context, vm) {
  let stratTeam = await vm.$http.get("items", {
    params: {
      "system.type": "person",
      "elements.team[contains]": "strategic",
      order: "system.name[asc]",
    },
  });
  context.commit("SET_STRAT_TEAM", objectify(stratTeam.data.items));

  let managementTeam = await vm.$http.get("items", {
    params: {
      "system.type": "person",
      "elements.team[contains]": "management",
      order: "system.name[asc]",
    },
  });
  context.commit("SET_MANAGEMENT_TEAM", objectify(managementTeam.data.items));

  let rdTeam = await vm.$http.get("items", {
    params: {
      "system.type": "person",
      "elements.team[contains]": "rd",
      order: "system.name[asc]",
    },
  });
  context.commit("SET_RD_TEAM", objectify(rdTeam.data.items));

  let scientificTeam = await vm.$http.get("items", {
    params: {
      "system.type": "person",
      "elements.team[contains]": "scientific",
      order: "system.name[asc]",
    },
  });
  context.commit("SET_SCIENTIFIC_TEAM", objectify(scientificTeam.data.items));
};

export let getPipelineItems = async function (context, vm) {
  vm.$http
    .get("items", {
      params: {
        "system.type": "pipeline_item",
        order: "elements.level[desc]",
      },
    })
    .then((response) => {
      var pipelineItems = response.data.items;
      context.commit("SET_PIPELINE_ITEMS", pipelineItems);
    });
};

export let getNewsArticles = async function (context, vm) {
  vm.$http
    .get("items", {
      params: {
        "system.type": "article",
        order: "elements.date[asc]",
      },
    })
    .then((response) => {
      var newsArticles = response.data.items.reverse();
      context.commit("SET_NEWS_ARTICLES", newsArticles);
    });
};

export let getTechnologySectionContent = async function (context, vm) {
  vm.$http.get("items/technology_page").then((response) => {
    var pageContent = response.data.item;
    context.commit("SET_TECHNOLOGY_SECTION_CONTENT", pageContent);
  });
};

export let getCompanySectionContent = async function (context, vm) {
  vm.$http.get("items/company_page").then((response) => {
    var pageContent = response.data.item;
    context.commit("SET_COMPANY_SECTION_CONTENT", pageContent);
  });
};

export let getPipelineSectionContent = async function (context, vm) {
  vm.$http.get("items/pipeline_page").then((response) => {
    var pageContent = response.data.item;
    context.commit("SET_PIPELINE_SECTION_CONTENT", pageContent);
  });
};

export let getLegalNotices = async function (context, vm) {
  vm.$http.get("items/legal_notices").then((response) => {
    var pageContent = response.data.item;
    context.commit("SET_LEGAL_NOTICES_CONTENT", pageContent);
  });
};

export let getPublications = async function (context, vm) {
  vm.$http
    .get("items", {
      params: {
        "system.type": "publication",
        order: "system.name[asc]",
      },
    })
    .then((response) => {
      var publications = response.data.items.reverse();
      context.commit("SET_PUBLICATIONS", publications);
    });
};
