<template>
  <div class="main-menu-sm fixed-top">
    <nav role="navigation">
      <div id="menuToggle">
        <input type="checkbox" id="checkbox" />
        <span class="burger"></span>
        <span class="burger"></span>
        <span class="burger"></span>

        <ul id="menu">
          <li>
            <router-link class="links" href="#" to="/#technology">{{$t("technology")}}</router-link>
          </li>
          <li>
            <router-link class="links" href="#" to="/#pipeline">Pipeline</router-link>
          </li>
          <li>
            <router-link class="links" href="#" to="#company">{{$t("company")}}</router-link>
          </li>
          <li>
            <router-link class="links" href="#" to="/#news">{{$t("news")}}</router-link>
          </li>
          <li>
            <router-link class="links" href="#" to="/#contact">Contact</router-link>
          </li>
          <li>
            <a
              class="mr-3"
              @click="setLocale('fr')"
              :class="$root.$i18n.locale == 'fr' ? 'text-secondary' : 'text-white'"
            >Fr</a>
            <span style="color: rgba(255, 255, 255, 0.7);">|</span>
            <a
              class="pl-3"
              @click="setLocale('en')"
              :class="$root.$i18n.locale == 'en' ? 'text-secondary' : 'text-white'"
            >En</a>
          </li>
        </ul>
      </div>
      <div id="top-bar">
        <router-link id href="#" to="/">
          <div id="logo-container">
            <img src="../assets/logo/inverted.png" id="logo" alt />
          </div>
        </router-link>
      </div>

      <button
        id="back-btn"
        @click="$router.go(-1)"
        v-if="!isHomePage"
        class="text-white"
      >{{$t("back")}} &#8594;</button>
    </nav>
  </div>
</template>

<script>
export default {
  computed: {
    isHomePage() {
      return this.$route.path == "/";
    },
  },
  mounted() {
    const hamburger = document.querySelector(".burger");
    const peptinov = document.querySelector(".peptinov");
    const links = document.getElementsByClassName("links");

    hamburger.addEventListener("click", () => {
      peptinov.classList.toggle("hidden");
    });

    Array.from(links).forEach((element) => {
      element.addEventListener("click", () => {
        document.getElementById("checkbox").checked = false;
      });
    });
  },

  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    backToHomePage() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/global.scss";
@import "../assets/styles/responsive.scss";

body {
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: fade-out($white, 0.3);
  opacity: 1;
  font-size: 1.5em;
  font-weight: 400;
  transition: 200ms;
  @media (max-width: 370px) {
    font-size: 1.2em;
  }
}
a:hover {
  text-decoration: none;
  color: $white;
}
ul {
  padding: 0;
  list-style-type: none;
}

nav {
  background-color: $primary;
  height: 65px;
}

#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span.burger {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: white;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span.burger {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: white;
}
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 180px;
  width: 70vw;
  height: 400px;
  height: 120vh;
  box-shadow: 0 0 10px black;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  background-color: #f5f6fa;
  background-color: $primary;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  transition-delay: 2s;
}

#menuToggle input:checked ~ ul {
  transform: none;
}

#back-btn {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.6em;
  border: solid 1px white;
  border-radius: 15px;
  background-color: transparent;
  padding: auto;
  z-index: 10;
}

#top-bar {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#logo-container {
  width: 100%;
  height: 100%;
  padding: auto;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-content: center;
  // background-color: #fff;
  text-align: center;
}
#logo {
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  padding: 0;
  width: 50%;
}
</style>

<i18n>
{
  "en" : {
    "company" : "Company",
    "technology": "Technology",
    "news" : "News",
    "back" : "back"
  },
  "fr" : {
    "company" : "Entreprise",
    "technology" : "Technologie",
    "news" : "Actualités",
    "back" : "retour"
  }
}
</i18n>