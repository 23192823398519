<template>
  <div class="section" v-if="pipelineSectionContent">
    <div class="section-intro left light" :class="$mq=='sm' ? 'sm' : ''">
      <div>
        <h2 class="title">{{$t("title")}}</h2>
        <hr class="divider" />
        <div
          class="intro"
          v-html="pipelineSectionContent.elements['content_' + $i18n.locale].value"
        ></div>
        <div>
          <router-link :to="{name: 'pipelinePage'}">
            <button class="btn btn-xl">{{$t("more")}}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {
      defaultPage: 1,
      id: "pipeline"
    };
  },
  computed: {
    ...mapGetters(["pipelineSectionContent"])
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";

li {
  list-style: none;
}

.main {
  background-color: $grey;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

.section {
  background: url("../../assets/img/tubes.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.slide {
  width: 100vw;
  height: 100vh;
  padding: auto auto;
}

.row {
  margin: 0;
}

.left,
.slide-advantages {
  background-color: rgba($primary, 0.8);
}
.slide-plateform {
  background-color: rgba($primary, 0.8);
}
.col-content {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  // color: $primary;
}
#close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, 50%);
  z-index: 10;
}

.scroll {
  background-color: tomato;
  width: 50%;
  height: 200px;
  overflow: scroll;
}
</style>


<i18n>
{
  "en" : {
    "title" : "Pipeline",
    "more" : "Discover"
  },
  "fr" : {
    "title"  : "Pipeline",
    "more" : "Découvrir"
  }
}
</i18n>