<template>
  <div
    class="avatar"
    @click="handleClick"
    :class="
      selected && person.system.codename == person.system.codename
        ? 'selected'
        : ''
    "
  >
    <img :src="person.elements.picture.value[0].url" class="image" />
    <div class="name-container">
      <div class="name">
        {{ person.elements[`name_${$i18n.locale}`].value }}
      </div>
    </div>
    <hr class="divider" />
    <div class="short-title-container">
      <div class="short-title">
        {{ person.elements[`short_title_${$i18n.locale}`].value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
    selected: { default: false },
  },
  methods: {
    handleClick() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";

.image {
  width: 100px;
  border-radius: 15px;
  z-index: 1;
}
.short-title,
.name-container {
  width: 130px;
  font-size: 15px;
  text-align: center;
  line-height: 20px;
}
.name-container,
.short-title-container {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.short-title {
  padding-left: 4px;
  // text-align: left;
  text-transform: uppercase;
  font-size: 12px;
}
.person {
  background-color: rgba($primary, 0.8);
}
.divider {
  border-top: solid 0.1rem $primary;
  margin: 0;
  width: 100%;
}
.avatar {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  border-radius: 15px;
  border: 2px solid transparent;
  padding: 16px;
  padding-bottom: 8px;
  border: 1px solid rgba($primary, 0.5);
  &.selected {
    border: 2px solid $primary;
    padding: 16px 14px;
  }
  &:hover {
    transform: translateY(-10px);
  }
  transition: all 0.2s ease-out;
  height: 270px;
  // background-color: #bfcdd3;
}
</style>
