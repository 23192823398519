<template>
  <div class="page-container row">
    <div class="">
      <h3 class="primary-page-title">{{ $t("title") }}</h3>
      <div v-for="team in teamStructure" v-bind:key="team" class="team">
        <h4 class="secondary-page-title">{{ $t(team) }}</h4>
        <ul class="grid">
          <li v-for="person in teams[team]" v-bind:key="person.system.codename">
            <TeamMember
              :person="person"
              @clicked="setSelectedPerson(person)"
              :selected="
                selectedPerson &&
                selectedPerson.system.codename === person.system.codename
              "
            ></TeamMember>
          </li>
        </ul>
      </div>
    </div>
    <PersonDrawer :person="selectedPerson"></PersonDrawer>
  </div>
</template>

<script>
import TeamMember from "../TeamMember";
import PersonDrawer from "../PersonDrawer";
import { mapGetters } from "vuex";

export default {
  components: {
    PersonDrawer,
    TeamMember,
  },
  data: () => {
    return {
      selectedPerson: null,
      teamStructure: ["management", "rd", "strategic", "scientific"],
    };
  },
  computed: {
    ...mapGetters(["teams"]),
  },
  methods: {
    setSelectedPerson(person) {
      if (!this.selectedPerson) {
        this.selectedPerson = person;
      } else {
        if (this.selectedPerson.system.codename === person.system.codename) {
          this.selectedPerson = null;
        } else {
          this.selectedPerson = person;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/responsive.scss";

.main {
  height: 100vh;
  width: 100vw;
  padding: 0 !important;
  position: relative;
  margin: $navbar-height 0 $footer-height 0;
}

.teams {
}

.team {
  padding: 0;
  margin-bottom: 16px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 15%;
  }
}

.person {
  background-color: rgba($primary, 0.8);
  position: fixed;
  bottom: 5%;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  list-style: none;
  justify-content: space-around;
  @include sm {
    justify-content: flex-start;
  }
}

li {
  margin: 0;
  @include sm {
    margin: 0 2%;
  }
  &:last-of-type {
    margin-bottom: 20px;
  }
}
</style>

<i18n>
{
  "fr" : {
    "title" : "L'équipe Peptinov",
    "management": "Management",
    "rd" : "Equipe R&D",
    "strategic" : "Conseil stratégique",
    "scientific" : "Conseil médical & scientifique"
    
  },
  "en" : {
    "title" : "Peptinov team",
    "management": "Management",
    "rd" : "R&D team",
    "strategic" : "Strategic board",
    "scientific" : "Medical and scientific board"
  }
}
</i18n>
