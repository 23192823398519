<template>
  <footer class="bg-light footer">
    <div class="small text-center text-muted">Copyright &copy; {{ year }} - Peptinov</div>

    <div class="text-center small text-muted">
      <router-link to="/legal">{{$t("legal")}}</router-link>
    </div>
  </footer>
</template>

<script>
export default {
  data: () => {
    return {
      year: null
    };
  },
  computed: {
    fixed: function() {
      return !this.routeIsHome;
    }
  },

  created: function() {
    this.year = new Date().getFullYear();
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
.footer {
  padding: 24px 0;
}
</style>

<i18n>
{
  "fr": {
    "legal": "Mentions légales"
  },
  "en": {
    "legal": "Legal notices"
  }
}
</i18n>