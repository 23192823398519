<template>
  <div class="section bg-primary" ref="keypoints_section">
    <div class="title">
      <h2 class="text-white mt-0">{{ $t("keypointsSection.sectionTitle") }}</h2>
      <hr class="divider my-4" />
    </div>

    <div class="keypoints">
      <mq-layout mq="sm">
        <swiper
          :options="{
            autoplay: true,
            'mouse-drag': true,
          }"
        >
          <swiperSlide
            class="col-lg-4"
            v-for="item in items"
            v-bind:key="item.title.fr"
          >
            <KeyPoint :item="item"></KeyPoint>
          </swiperSlide>
        </swiper>
      </mq-layout>
      <mq-layout mq="md+">
        <div class="row" v-if="loaded">
          <div
            class="col-4"
            v-for="(item, index) in items"
            v-bind:key="item.title.fr"
          >
            <KeyPoint :item="item" :class="`keypoint-${index}`"></KeyPoint>
          </div>
        </div>
      </mq-layout>
    </div>
  </div>
</template>

<script>
import KeyPoint from "@/components/KeyPoint";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    KeyPoint,
    swiper,
    swiperSlide,
  },

  data: () => {
    return {
      items: null,
      loaded: false,
    };
  },
  created: function () {
    fetch("keypoints.json")
      .then((res) => res.json())
      .then((data) => {
        this.items = data.keypoints;
        this.loaded = true;
      });
  },
  mounted: function () {
    window.onscroll = this.scroll;
  },
  methods: {
    scroll() {
      // var section = this.$refs["keypoints_section"];
      // Array.from(document.getElementsByClassName("keypoint-0")[0]).offsetY +=
      //   window.pageYOffset - section.offsetTop;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: linear-gradient(to bottom, rgba($primary, 1), rgba($primary, 0.5)),
    url("../../assets/img/glasses.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.title {
  text-align: center;
  margin: 0 5% 10%;
}
.keypoints {
  width: 100%;
}
.col-4,
.row {
  margin: 0;
  padding: 0;
}
</style>
