import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import {
  getTeams,
  getPipelineItems,
  getNewsArticles,
  getPublications,
  getTechnologySectionContent,
  getCompanySectionContent,
  getPipelineSectionContent,
  getLegalNotices,
} from "./api";

export default new Vuex.Store({
  state: {
    sections: {
      technology: null,
      company: null,
      pipeline: null,
    },
    teams: {
      rd: null,
      management: null,
      strategic: null,
      scientific: null,
    },
    pipelineItems: [],
    newsArticles: [],
    publications: [],
    legalNotices: null,
  },
  mutations: {
    SET_STRAT_TEAM(state, stratTeam) {
      state.teams.strategic = stratTeam;
    },
    SET_MANAGEMENT_TEAM(state, managementTeam) {
      state.teams.management = managementTeam;
    },
    SET_RD_TEAM(state, rdTeam) {
      state.teams.rd = rdTeam;
    },
    SET_SCIENTIFIC_TEAM(state, scientificTeam) {
      state.teams.scientific = scientificTeam;
    },

    SET_PIPELINE_ITEMS(state, items) {
      state.pipelineItems = items;
    },
    SET_NEWS_ARTICLES(state, items) {
      state.newsArticles = items;
    },
    SET_PUBLICATIONS(state, items) {
      state.publications = items;
    },
    SET_TECHNOLOGY_SECTION_CONTENT(state, content) {
      state.sections.technology = content;
    },
    SET_COMPANY_SECTION_CONTENT(state, content) {
      state.sections.company = content;
    },
    SET_PIPELINE_SECTION_CONTENT(state, content) {
      state.sections.pipeline = content;
    },
    SET_LEGAL_NOTICES_CONTENT(state, content) {
      state.legalNotices = content;
    },
  },
  actions: {
    async GET_TEAMS(context) {
      getTeams(context, this._vm);
    },
    async GET_PIPELINE_ITEMS(context) {
      getPipelineItems(context, this._vm);
    },
    async GET_NEWS_ARTICLES(context) {
      getNewsArticles(context, this._vm);
    },
    async GET_PUBLICATIONS(context) {
      getPublications(context, this._vm);
    },
    async GET_TECHNOLOGY_SECTION_CONTENT(context) {
      getTechnologySectionContent(context, this._vm);
    },
    async GET_COMPANY_SECTION_CONTENT(context) {
      getCompanySectionContent(context, this._vm);
    },
    async GET_PIPELINE_SECTION_CONTENT(context) {
      getPipelineSectionContent(context, this._vm);
    },
    async GET_LEGAL_NOTICES_CONTENT(context) {
      getLegalNotices(context, this._vm);
    },
  },
  getters: {
    teamsLoaded: (state) => {
      let res =
        Boolean(state.teams.rd) &&
        Boolean(state.teams.strategic) &&
        Boolean(state.teams.management);
      return res;
    },
    newsArticles: (state) => {
      return state.newsArticles;
    },
    publications: (state) => {
      return state.publications;
    },
    teams: (state) => {
      return state.teams;
    },
    technologySectionContent: (state) => {
      return state.sections.technology;
    },
    companySectionContent: (state) => {
      return state.sections.company;
    },
    pipelineSectionContent: (state) => {
      return state.sections.pipeline;
    },
    legalNoticesContent: (state) => {
      return state.legalNotices;
    },
  },
});
