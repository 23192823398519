<template>
  <div class="page-container">
    <h3 class="primary-page-title">Technologie</h3>
    <h4 class="secondary-page-title">{{ $t("title") }}</h4>
    <div class="row steps">
      <div class="step" v-bind:class="entering_step1 ? 'entering' : ''">
        <div class="img">
          <img src="../../assets/icons/syringe.png" />
        </div>
        <div class="text">
          <p>I</p>
          <p>{{ $t("step1") }}</p>
        </div>
      </div>
      <div class="step" v-bind:class="entering_step2 ? 'entering' : ''">
        <div class="img">
          <img src="../../assets/icons/injection.png" />
        </div>
        <div class="text">
          <p>II</p>
          <p>{{ $t("step2") }}</p>
        </div>
      </div>
      <div class="step" v-bind:class="entering_step3 ? 'entering' : ''">
        <div class="img">
          <img class="syringe" src="../../assets/icons/cell.png" />
        </div>
        <div class="text">
          <p>III</p>
          <p>{{ $t("step3") }}</p>
        </div>
      </div>
    </div>
    <div class="main-text">
      <div :class="$mq == 'sm' ? '' : 'row'">
        <div :class="$mq == 'sm' ? '' : 'col'">
          <p>{{ $t("bottom1") }}</p>
          <p>{{ $t("bottom2") }}</p>
          <p>{{ $t("bottom3") }}</p>
        </div>
        <div :class="$mq == 'sm' ? '' : 'col'">
          <iframe
            class="video"
            :src="`https://www.youtube.com/embed/${$t('videoId')}`"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <hr class="divider" />
    </div>
    <div class="articles">
      <h4 class="secondary-page-title">{{ $t("articlesTitle") }}</h4>
      <div class="article-list">
        <ArticleList></ArticleList>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleList from "../ArticleList";

export default {
  components: {
    ArticleList,
  },

  data: () => {
    return {
      entering_step1: 0,
      entering_step2: 0,
      entering_step3: 0,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      setTimeout(() => {
        vm.entering_step1 = 1;
      }, 400);
      setTimeout(() => {
        vm.entering_step2 = 1;
      }, 600);
      setTimeout(() => {
        vm.entering_step3 = 1;
      }, 800);
      return vm;
    });
  },
  beforeRouteLeave(to, from, next) {
    to, from, next;
    this.entering_step1 = 0;
    this.entering_step2 = 0;
    this.entering_step3 = 0;
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/responsive.scss";

.row {
  margin: 0;
}

.main {
  padding: 0;
  padding-top: 100px;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: black;

  .title {
    padding: 0 5%;
  }

  .main-text,
  .row {
    padding: 30px 5%;
  }
  .row {
    justify-content: space-between;
    width: 100vw;
  }
  .main-text {
    border-top: solid 1px white;
    background-color: rgb(241, 241, 241);
  }
}

.articles {
  padding: 42px 0;
}

.main-text,
.row {
  padding: 30px 5%;
}

.main-text {
  background-color: $primary;
  color: white;
}

.steps {
  justify-content: space-around;
}

.step {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: opacity 1s ease-out;
  @include md {
    width: 30%;
  }
  .img {
    height: 200px;
    width: 200px;
    border-radius: 200px;
    margin-bottom: 20px;
    padding: 0;
    img {
      display: inline;
      margin: 0 auto;

      height: 100%;
      width: auto;
    }
  }
  .text {
    height: auto;
    margin: auto;
    max-width: 350px;
  }
  opacity: 0;
  &.entering {
    opacity: 1;
  }
}
.video-container {
  width: 100%;
  margin: 46px auto;
  max-width: 800px;
  height: 450px;
}
.video {
  height: 100%;
  width: 100%;
}

.video-section {
  padding-top: 20px;
}
</style>

<i18n>
{
  "fr": {
    "title": "L'approche de Peptinov",
    "step1": "Production d’un conjugué immunogène spécifique de la protéine ciblée",
    "step2": "Injection sous-cutanée du conjugué immunogène au patient",
    "step3": "Réponse anticorps du patient neutralisant la protéine ciblée",
    "bottom1": "L'approche thérapeutique innovante de Peptinov repose sur des peptides spécifiques dérivés des protéines ciblées, dont la selection résulte du savoir-faire unique de Peptinov.",
    "bottom2": "Une fois sélectionné, un peptide est associé à une protéine porteuse afin d'obtenir un conjugué immunogène qui sera ensuite injecté au patient.",
    "bottom3": "En réponse au traitement, l’organisme du patient génère des anticorps qui vont neutraliser la protéine ciblée.",
    "videoId": "0MJlqsbGJGs",
    "articlesTitle": "Publications"
  },
  "en": {
    "title": "Peptinov's approach",
    "step1": "Production of an immunogenic conjugate specific of the target protein",
    "step2": "Injection of the subcutaneous conjugate to the patient",
    "step3": "Patient’s antibody response, neutralising the target protein",
    "bottom1": "Peptinov’s immunotherapy is based on peptides derived from the target protein selected thanks to Peptinov’s unique know-how.",
    "bottom2": "Each peptide is linked to a carrier protein to form an immunogenic conjugate, which is injected to the patient (subcutaneous injection).",
    "bottom3": "In response, the patient’s immune system generates antibodies which will neutralise the target protein.",
    "videoId": "8j0GvgqPjxQ",
    "articlesTitle": "Publications"
  }
}
</i18n>
