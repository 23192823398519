<template>
  <div class="main">
    <div id="page-top"></div>
    <Masthead id="masthead"></Masthead>
    <!-- <Highlight id="highlight"></Highlight> -->
    <KeyPoints id="keypoints"></KeyPoints>
    <Technology id="technology"></Technology>
    <PipelineSection id="pipeline"></PipelineSection>
    <Company id="company"></Company>
    <div id="news">
      <News v-if="$mq=='lg' || $mq=='xl'"></News>
      <SmallDeviceNews v-else></SmallDeviceNews>
    </div>

    <Contact id="contact"></Contact>
  </div>
</template>

<script>
import Masthead from "./sections/Masthead";
// import Highlight from "./sections/Highlight";
import KeyPoints from "./sections/KeyPoints";
import Technology from "./sections/Technology";
import PipelineSection from "./sections/PipelineSection";
import Company from "./sections/Company";
import News from "./sections/News";
import SmallDeviceNews from "./sections/SmallDeviceNews";
import Contact from "./sections/Contact";

export default {
  components: {
    Masthead,
    // Highlight,
    KeyPoints,
    Technology,
    PipelineSection,
    Company,
    News,
    SmallDeviceNews,
    Contact
  }
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
button {
  outline: none !important;
}

input[type="button"]::-moz-focus-inner {
  border: 0 !important;
}
.main {
  width: 100%;
}
</style>