<template>
  <div class="page-container">
    <h3 class="primary-page-title">{{ $t("title") }}</h3>
    <div class="" :class="$mq == 'sm' ? '' : 'row'">
      <div class="" :class="$mq == 'sm' ? '' : 'col'">
        <div class="mb-2">
          <div class="timeline">
            <img
              v-if="$i18n.locale == 'fr'"
              src="../../assets/img/pipeline_fr.jpg"
            />
            <img v-else src="../../assets/img/pipeline_en.jpg" />
          </div>
        </div>
      </div>
      <!-- SMALL DEVICE -->
      <div v-if="$mq == 'sm'" class="list">
        <div
          v-for="pipeline in $store.state.pipelineItems"
          v-bind:key="pipeline.system.id"
          class="list-item"
        >
          <div @click="setSelected(pipeline)" class="pipeline-item">
            {{ pipeline.elements[`short_title_${$i18n.locale}`].value }}
          </div>
        </div>
        <PipelineDrawer :pipelineItem="selectedPipeline"></PipelineDrawer>
      </div>
      <!-- LARGE DEVICE -->
      <div class="col pt-5 right" v-else>
        <div class="select-container">
          <select v-model="selectedPipeline" class="select">
            <option disabled :value="null">{{ $t("click_for_more") }}</option>
            <option
              v-for="pipeline in $store.state.pipelineItems"
              v-bind:key="pipeline.system.id"
              :value="pipeline"
              class="option"
            >
              <div class="option-content">
                {{ pipeline.elements[`short_title_${$i18n.locale}`].value }}
              </div>
            </option>
          </select>
        </div>

        <div v-if="selectedPipeline">
          <PipelineItem :item="selectedPipeline"></PipelineItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PipelineItem from "@/components/PipelineItem";
import PipelineDrawer from "@/components/PipelineDrawer";

export default {
  data: () => {
    return {
      selectedPipeline: null,
    };
  },
  components: {
    PipelineItem,
    PipelineDrawer,
  },
  methods: {
    setSelected(pipeline) {
      this.selectedPipeline = pipeline;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/responsive.scss";

.main {
  padding: 0;
  padding-top: 70px;
  margin: 0;
  height: 100vh;
  width: 100%;
}

.header {
  background-color: white;
  width: 100vw;
}

.pipeline-item {
  width: 90%;
  border-radius: 15px;
  border: 4px solid $primary;
  color: black;
  padding: 8px;
  margin-bottom: 16px;
  margin-left: 8px;
  &.selected {
    border: 4px solid $secondary;
    transform: translateX(20px);
  }
  &:hover {
    transform: translateX(20px);
  }
  transition: all 0.2s ease-out;
  // &:last-child {
  //   border-color: red;
  // }
}

.left {
  background-color: $grey;
  padding-top: $small-device-navbar-height;
  @include md {
    padding-top: $navbar-height;
  }
}

.right {
  background-color: rgba($primary, 0.8);
}

li {
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
}

.section {
  background: url("../../assets/img/tubes.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.slide {
  width: 100vw;
  height: 100vh;
  padding: auto auto;
}

.row {
  margin: 0;
}

.slide-advantages {
  background-color: rgba($grey, 0.8);
}
.slide-plateform {
  background-color: rgba($primary, 0.8);
}
.col-content {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: $primary;
}
#close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, 60%);
  // z-index: 10;
}

.timeline {
  width: 100%;
  img {
    width: 100%;
  }
}
.select-container {
  width: 100%;
  margin-top: 0;
}
.select {
  // margin-top: 1em;
  width: 90%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 0 30px 0 10px !important;
  -webkit-padding-end: 80px !important;
  -webkit-padding-start: 10px !important;
  &:focus {
    outline: none;
  }
}
.option {
  text-overflow: ellipsis;
  padding: 0 30px 0 10px !important;
  -webkit-padding-end: 80px !important;
  -webkit-padding-start: 10px !important;
  .option-content {
    width: 100px;
    overflow: hidden;
  }
}
</style>

<i18n>
{
  "fr" : {
    "title" : "Nos solutions en cours de développement",
    "click_for_more" : "Sélectionnez un produit pour en savoir plus"
  },
  "en" : {
    "title" : "Our solutions under development",
    "click_for_more" : "Select an item for details"
  }
}
</i18n>
