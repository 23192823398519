<template>
  <div>
    <div v-for="publication in publications" v-bind:key="publication.system.id">
      <ArticleListItem :article="publication"></ArticleListItem>
    </div>
    <hr class="divider" />
  </div>
</template>

<script>
import ArticleListItem from "./ArticleListItem.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    ArticleListItem,
  },
  computed: {
    ...mapGetters(["publications"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";

.divider {
  border-top: solid 0.1rem $secondary;
}
</style>
