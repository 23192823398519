<template>
  <div id="person-drawer">
    <div v-if="person">
      <div class="header" @click="toggleDrawer">
        <div class="person-info">
          <div class="person-name">
            {{ person.elements[`name_${$i18n.locale}`].value }}
          </div>
          <div class="person-title">
            {{ person.elements[`title_${$i18n.locale}`].value }}
          </div>
        </div>

        <button class="more btn">+</button>
      </div>

      <div
        v-html="person.elements[`description_${$i18n.locale}`].value"
        class="description"
      ></div>
    </div>

    <div v-else>{{ $t("teamsPage.more") }}</div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      required: true,
    },
  },
  watch: {
    person(newVal, oldVal) {
      if (newVal) {
        this.drawer.classList.add("open");
        return oldVal;
      }
    },
  },
  data: () => {
    return {
      drawer: null,
    };
  },
  mounted() {
    this.drawer = document.getElementById("person-drawer");
  },
  methods: {
    toggleDrawer: function () {
      this.drawer.classList.toggle("open");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";

#person-drawer {
  z-index: 2;
  overflow: hidden;
  position: fixed;
  top: 85%;
  padding: 24px;
  padding-top: 10px;
  right: 0%;
  background-color: rgba(255, 255, 255, 0.897);
  height: 80%;
  transition: all 0.5s ease-out;
  max-width: 500px;
  border-top: solid 3px $primary;
  @media (min-width: 500px) {
    border-left: solid 3px $primary;
    border-top-left-radius: 15px;
  }

  .header {
    height: 15vh;
    cursor: pointer;

    .person-info {
      width: 90%;
      position: relative;
      .person-name {
        color: $primary;
        font-weight: 500;
      }
    }
    .btn:focus {
      outline: none;
      border: none;
    }
    .btn:focus,
    .btn:active {
      outline: none !important;
      box-shadow: none;
    }
    .more {
      font-size: 40px;
      width: 10%;
      outline: 0 !important;
      border: none !important;
      position: absolute;
      top: 0%;
      right: 5%;
      transition: all 0.5s ease-out;
    }
  }
  .description {
    height: 60vh;
    overflow-y: scroll !important;
  }

  &.open {
    top: 20%;
    // bottom: 0%;
    max-width: 700px;
    .more {
      transform: rotate(45deg);
    }
  }
  .divider {
    border-top: solid 0.1rem $primary;
    margin: 0;
    width: 100%;
  }
}
</style>
