<template>
  <div class="pipeline-item-container text-white">
    <div class="header">
      <p>{{ item.elements["title_" + $i18n.locale].value }}</p>
    </div>
    <div class="mt-3 text-uppercase">
      <p>Phase : {{ item.elements["step_" + $i18n.locale].value }}</p>
    </div>
    <div class="row">
      <div class="col my-auto">
        <progress-bar
          v-bind:val="currentValue"
          size="large"
          :bar-border-radius="20"
          bar-color="#f3a712"
          text-fg-color="#f3a712"
        ></progress-bar>
        <div class="row phases">
          <div class="col text-center phase label-container">
            <div class="label">{{$t("pipelineLabels.discovery")}}</div>
          </div>
          <div class="col text-center phase">
            <div class="label">{{$t("pipelineLabels.selection")}}</div>
          </div>
          <div class="col text-center phase">
            <div class="label">{{$t("pipelineLabels.preclinical")}}</div>
          </div>
          <div class="col text-center phase">
            <div class="label">{{$t("pipelineLabels.clinical")}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="item.elements['text_' + $i18n.locale].value.length > 50">
      <div class="description" v-html="item.elements['text_' + $i18n.locale].value"></div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "vue-simple-progress";

export default {
  components: {
    ProgressBar
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  watch: {
    item: function(val) {
      this.animate();
      return val;
    }
  },
  data: () => {
    return {
      currentValue: 0,
      timer: null
    };
  },

  mounted() {
    this.animate();
  },
  methods: {
    animate() {
      setTimeout(() => {
        this.currentValue = this.item.elements.level.value;
      }, 400);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";

.pipeline-item-container {
  overflow: scroll;
  padding: 24px;
}
.description {
  margin-top: 2em;
  height: 50vh;
  font-size: 24px;
  line-height: 26px;
  z-index: 1000000;
  padding-bottom: 5em;
  overflow: scroll;
}
.description ::v-deep a {
  color: white;
  text-decoration: underline;
  &:hover {
    color: $secondary;
  }
}

.phases {
  margin-top: 8px;
  @media (max-width: 1105px) {
    margin-top: 20px;
  }
}
.phase {
  font-size: 16px;
  line-height: 24px;
  border-right: 2px solid white;
  &:last-of-type {
    border-right: none;
  }
  @media (max-width: 1105px) {
    width: 30px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .label {
    line-height: 20px;
    @media (max-width: 1105px) {
      transform: rotate(50deg);
    }
  }
}
</style>

<i18n>
{
  "fr" : {
    "more" : "En savoir plus"
  },
  "en" : {
    "more" : "Read more"
  }
}
</i18n>
