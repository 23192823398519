<template>
  <div id="pipeline-viewer">
    <div v-if="pipelineItem">
      <div class="header">
        <div class="name">{{pipelineItem.elements[`short_title_${$i18n.locale}`].value}}</div>
        <div class="phase">Phase: {{ pipelineItem.elements["step_" + $i18n.locale].value }}</div>
        <ProgressBar
          v-bind:val="currentValue"
          size="large"
          :bar-border-radius="20"
          bar-color="#f3a712"
          text-fg-color="#f3a712"
        ></ProgressBar>
        <button v-if="hasContent" class="more btn" @click="toggleDrawer">+</button>
      </div>
      <div v-if="pipelineItem.elements['text_' + $i18n.locale].value.length > 50">
        <div class="description pt-3" v-html="pipelineItem.elements['text_' + $i18n.locale].value"></div>
      </div>
    </div>

    <div v-else>Cliquez sur un produit pour en savoir plus</div>
  </div>
</template>

<script>
import ProgressBar from "vue-simple-progress";

export default {
  props: {
    pipelineItem: {
      required: true
    }
  },
  components: {
    ProgressBar
  },
  watch: {
    pipelineItem: function(val) {
      this.animate();
      this.drawer.classList.add("open");
      return val;
    }
  },
  data: () => {
    return {
      drawer: null,
      currentValue: 0,
      timer: null
    };
  },
  mounted() {
    this.drawer = document.getElementById("pipeline-viewer");
  },
  computed: {
    hasContent() {
      return (
        this.pipelineItem.elements["text_" + this.$i18n.locale].value.length >
        50
      );
    }
  },
  methods: {
    toggleDrawer() {
      this.drawer.classList.toggle("open");
    },
    animate() {
      setTimeout(() => {
        this.currentValue = this.pipelineItem.elements.level.value;
      }, 400);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";

#pipeline-viewer {
  width: 100vw;
  overflow: hidden;
  .header {
    height: 15vh;

    .phase {
      margin-bottom: 0.5em;
      font-size: 16px;
    }
    .btn:focus {
      outline: none;
      border: none;
    }
    .btn:focus,
    .btn:active {
      outline: none !important;
      box-shadow: none;
    }
    .more {
      font-size: 40px;
      width: 10%;
      outline: 0 !important;
      border: none !important;
      position: absolute;
      top: 0%;
      right: 5%;
      transition: all 0.5s ease-out;
    }
  }
  .description {
    height: 60vh;
    overflow: scroll !important;
  }

  position: fixed;
  top: 85%;
  padding: 24px;
  &.open {
    top: 20%;
    // bottom: 0%;
    .more {
      transform: rotate(45deg);
    }
  }
  left: 0%;
  background-color: rgb(230, 230, 230);
  height: 80%;

  transition: all 0.5s ease-out;
}
</style>