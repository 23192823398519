<template>
  <div class="item">
    <div class="row content">
      <div class="col arrow">&gt;</div>
      <div class="col">
        <a
          class="click"
          target="_blank"
          :href="article.elements.file.value[0].url"
          >{{
            $i18n.locale == "fr"
              ? article.elements.title_fr.value
              : article.elements.title_en.value
          }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";

.item {
  color: $primary;
  a {
    text-decoration: underline;
    font-size: 20px;
  }
}
.content {
  padding: 0 64px;
}

.arrow {
  max-width: 32px;
}
</style>
