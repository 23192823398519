<template>
  <div class="section" v-if="companySectionContent">
    <div class="section-intro left dark" :class="$mq == 'sm' ? 'sm' : ''">
      <div>
        <h2 class>{{ $t("title") }}</h2>
        <hr class="divider" />
        <div
          class="intro"
          v-html="
            companySectionContent.elements['content_' + $i18n.locale].value
          "
        ></div>
        <div class>
          <router-link :to="{ name: 'companyPage' }">
            <button class="btn btn-xl">{{ $t("more") }}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {
      id: "company",
      selected: null
    };
  },
  computed: {
    ...mapGetters(["companySectionContent"])
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/responsive.scss";

.section {
  background: url("../../assets/img/cochin.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>

<i18n>
{
  "en" : {
    "title" : "Company",
    "more" : "Discover"
  },
  "fr" : {
    "title"  : "Entreprise",
    "more" : "Découvrir"
  }
}
</i18n>
