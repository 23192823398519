<template>
  <div class="section">
    <div class="header text-center">
      <h2 class="text-primary">{{$t("newsSection.sectionTitle")}}</h2>
      <hr class="divider my-4" />
    </div>
    <div class="articles" dir="rtl">
      <swiper ref="newsSwiper" class="swiper" :options="{slidesPerView: 1, slidesPerGroup:1}">
        <swiperSlide v-for="article in newsArticles" v-bind:key="article.system.id" class="slide">
          <div dir="ltr" class="article-content">
            <NewsArticle :article="article"></NewsArticle>
          </div>
        </swiperSlide>
      </swiper>
    </div>
    <div class="controls">
      <button @click="swiper.slideNext()" id="older" class="btn btn-secondary btn-sm text-white">
        <span class="mr-2">&#8592;</span>
        <!-- {{ $t("newsSection.olderArticlesButtonsLabel") }} -->
      </button>
      <button @click="swiper.slidePrev()" id="newer" class="btn btn-secondary btn-sm text-white">
        <!-- {{ $t("newsSection.newerArticlesButtonsLabel") }} &nbsp; -->
        <span class="ml-2">&#8594;</span>
      </button>
    </div>
  </div>
</template>

<script>
import NewsArticle from "@/components/NewsArticle";

import { swiper, swiperSlide } from "vue-awesome-swiper";
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {
      swiper: null,
      defaultPage: 0,
      id: "news",
    };
  },
  components: {
    NewsArticle,
    swiper,
    swiperSlide,
  },
  computed: {
    ...mapGetters(["newsArticles"]),
  },
  mounted() {
    var newer = document.getElementById("newer");
    newer.disabled = true;
    var older = document.getElementById("older");
    this.swiper = this.$refs.newsSwiper.swiper;
    this.swiper.on("reachEnd", function () {
      older.disabled = true;
    });
    this.swiper.on("reachBeginning", function () {
      newer.disabled = true;
    });
    this.swiper.on("fromEdge", function () {
      older.disabled = false;
      newer.disabled = false;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";

.section {
  padding-top: $navbar-height;

  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 600px;

  .slide {
    // display: flex;
    height: 100%;
  }
  .header {
    width: 100%;
  }
  .controls {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 1;
    margin-bottom: 12px;
    button {
      border: none !important;
      outline: none !important;
      padding: 8px;
      width: 30%;
      font-size: 25px;
    }
  }
}

.articles {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 60vh;
  z-index: 200;
}
.article-content {
  height: 100%;
}
.slide {
  width: 100%;
}
.swiper {
  height: 100%;
}
// .btn {
//   border-radius: ;
// }
</style>

<i18n>
{
  "en" : {
    "title" : "News",
    "more" : "See all"
  },
  "fr" : {
    "title"  : "Actualités",
    "more" : "Voir tout"
  }
}
</i18n>