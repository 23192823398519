<template>
  <div>
    <div class="section" v-if="technologySectionContent">
      <div class="section-intro dark" :class="$mq=='sm' ? 'sm' : ''">
        <div>
          <h2>{{$t("title")}}</h2>
          <hr class="divider" />
          <div
            class="intro"
            v-html="technologySectionContent.elements['content_' + $i18n.locale].value"
          ></div>
          <router-link :to="{name: 'technologyPage'}">
            <button class="btn btn-secondary btn-xl">{{$t("more")}}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {
      defaultPage: 0,
      id: "technology"
    };
  },
  computed: {
    ...mapGetters(["technologySectionContent"])
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";

.advantages {
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.section {
  background: url("../../assets/img/tubes.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
}

.row {
  margin: 0;
}

#close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, 60%);
  z-index: 20;
}
</style>

<i18n>
{
  "en" : {
    "title" : "Technology",
    "more" : "Discover"
  },
  "fr" : {
    "title"  : "Technologie",
    "more" : "Découvrir"
  }
}
</i18n>