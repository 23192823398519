import Vue from "vue";
import App from "./App.vue";

// STYLING

require("@/assets/styles/main.scss");

// COMPONENTS

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
Vue.use(VueAwesomeSwiper, { allowTouchMove: false });

import VueYouTubeEmbed from "vue-youtube-embed";
Vue.use(VueYouTubeEmbed);

// OTHER

import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo, {
  duration: 1000,
});

import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    sm: 767,
    md: 991,
    lg: 1399,
  },
});

import "./directives";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// CREATING VUE INSTANCE

Vue.config.productionTip = false;
import i18n from "./i18n";
import store from "./store";
import router from "./router";

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
