<template>
  <div class="section">
    <div class="content">
      <div class="text-center header">
        <h2 class="text-white">Contact</h2>
        <hr class="divider" />
      </div>
      <div class="row">
        <div class="col-sm-6 left">
          <Form></Form>
        </div>
        <div class="col-sm-6 right">
          <iframe
            id="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.0799740196308!2d2.3391358158235187!3d48.83761321012321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e671c0bd11b469%3A0xed232704a0b7e46!2sPeptinov!5e0!3m2!1sfr!2sfr!4v1576342700942!5m2!1sfr!2sfr"
            width="90%"
            height="100%"
            frameborder="1"
            style="border:0;"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/Form";
export default {
  components: { Form }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/responsive.scss";

.section {
  background-color: $primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.content {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-top: 32px;
}
.header {
  top: 15%;
  width: 100%;
}

.left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include lg {
    padding: 0 100px;
  }
}

.right {
  padding-top: 24px;
}

#map {
  margin-left: auto;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  @include sm {
    padding-top: 20px;
  }
  @include lg {
    padding: 0 100px;
    padding-top: 20px;
  }
}
</style>

<i18n>
{
  "en" : {
    "title" : "Contact us",
    "name-field-label" : "Name",
    "submit-btn-txt" : "Send",
    "message-sent-confirmation" : "Your message was sent, thank you!",
    "message-error" : "Your message could not be sent, please try again.",
    "invalid-email" : "Invalid e-mail."
  },
  "fr" : {
    "title"  : "Nous contacter",
    "name-field-label" : "Nom",
    "submit-btn-txt" :"Envoyer",
    "message-sent-confirmation" : "Votre message a bien été envoyé, merci !",
    "message-error" : "Votre message n'a pas pu être envoyé, merci d'essayer à nouveau.",
    "invalid-email" : "Email invalide."
  }
}
</i18n>
