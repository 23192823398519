import Vue from "vue";
const VueScrollTo = require("vue-scrollto");

Vue.filter("prettyDate", function(date, locale) {
  locale, date;
  return new Date(date).toLocaleDateString();
});

Vue.directive("links-in-new-window", {
  inserted: function(el) {
    const anchors = el.querySelectorAll("a");

    anchors.forEach((anchor) => (anchor.target = "_blank"));
  },
});

Vue.directive("navigate-to", {
  bind: function(el, binding) {
    el.addEventListener("click", () => {
      if (!binding.value.route.path == "/") {
        binding.value.router.push("/");
      }
      // console.log(binding.value.route);
      if (binding.value.page) {
        VueScrollTo.scrollTo(`#${binding.value.page}`);
      }
    });
  },
});
