<template>
  <div class="main">
    <div class="header">
      <div class="title">{{ article.elements[`title_${$i18n.locale}`].value }}</div>
      <div class="date">{{ $d(new Date(article.elements.date.value)) }}</div>
    </div>
    <div class="article-container">
      <div class="article">
        <div v-if="$i18n.locale == 'fr'">
          <!-- FRENCH VERSION -->
          <div v-for="field in fr_fields" v-bind:key="field.label">
            <div v-if="field.type == 'text'">
              <div class="body">
                <div v-html="field.content.value"></div>
              </div>
            </div>
            <div v-if="field.type == 'video'">
              <youtube
                class="container"
                :video-id="$youtube.getIdFromURL(field.content.value)"
                mute
                :player-vars="{ start: 0, autoplay: 0 }"
                player-width="100%"
              ></youtube>
            </div>
          </div>
        </div>

        <div v-else-if="$i18n.locale == 'en'">
          <!-- ENGLISH VERSION -->
          <div v-for="field in en_fields" v-bind:key="field.label">
            <div v-if="field.type == 'text'">
              <div class="body">
                <div v-html="field.content.value"></div>
              </div>
            </div>
            <div v-if="field.type == 'video'">
              <youtube
                class="container"
                :video-id="$youtube.getIdFromURL(field.content.value)"
                mute
                :player-vars="{ start: 0, autoplay: 0 }"
                player-width="100%"
              ></youtube>
            </div>
          </div>
        </div>

        <div v-else>NO LANGUAGE DETECTED</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    article: {
      required: true,
      type: Object,
    },
  },
  data: () => {
    return {
      fr_fields: [],
      en_fields: [],
    };
  },
  created: function () {
    var fr_fields_meta = [
      { label: "body_1_fr", type: "text" },
      { label: "video_url_1_fr", type: "video" },
      { label: "body_2_fr", type: "text" },
      { label: "video_url_2_fr", type: "video" },
    ];
    var en_fields_meta = [
      { label: "body_1_en", type: "text" },
      { label: "video_url_1_en", type: "video" },
      { label: "body_2_en", type: "text" },
      { label: "video_url_2_en", type: "video" },
    ];

    fr_fields_meta.forEach((field_meta) => {
      if (!this.isEmpty(field_meta.label)) {
        this.fr_fields.push({
          content: this.article.elements[field_meta.label],
          type: field_meta.type,
        });
      }
    });

    en_fields_meta.forEach((field_meta) => {
      if (!this.isEmpty(field_meta.label)) {
        this.en_fields.push({
          content: this.article.elements[field_meta.label],
          type: field_meta.type,
        });
      }
    });
  },
  methods: {
    isEmpty(field) {
      return (
        !this.article.elements[field] ||
        this.article.elements[field].value == "" ||
        this.article.elements[field].value == "<p><br></p>"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";

.main {
  height: 100%;
}

.article-container {
  padding: 16px;
  margin: 0 24px;
  height: 50vh;
  overflow: scroll;
  // padding-bottom: 70px;
}

.header {
  padding: 16px;
  margin: 0 24px;
  .title {
    font-weight: 800;
  }
  .date {
    font-weight: 400;
    font-size: 20px;
    color: $primary;
  }
}

a {
  border: solid white 2px;
}

.article {
  width: auto;
  background-color: rgba(white, 0.6);
  // color: $primary;
  border-radius: 16px;
  z-index: 3;
  overflow-y: scroll;
  &:last-of-type {
    margin-bottom: 200px;
  }
}

.body {
  width: 100%;
  height: 100%;
  text-align: left;
  &::v-deep a {
    color: $primary;
    text-decoration: underline;
  }
  p:last-child {
    margin-bottom: 200px;
    padding-bottom: 0;
  }
}
</style>
