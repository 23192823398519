<template>
  <div>
    <form class="my-auto" @submit.prevent="sendMessage">
      <div class="form-group">
        <label for="name">{{ $t("name-field-label") }}</label>
        <input
          type="text"
          v-model.trim="$v.contactName.$model"
          :class="{ 'is-invalid': $v.contactName.$error }"
          class="form-control"
          id="name"
          name="from_name"
          :disabled="messageSent"
        />
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input
          type="email"
          v-model.trim="$v.email.$model"
          :class="{ 'is-invalid': $v.email.$error }"
          class="form-control"
          id="email"
          name="reply_to"
          :disabled="messageSent"
        />
      </div>
      <div class="form-group">
        <label for="message">Message</label>
        <textarea
          class="form-control"
          v-model="message"
          id="message"
          name="message_html"
          v-model.trim="$v.message.$model"
          :class="{ 'is-invalid': $v.message.$error }"
          :disabled="messageSent"
        />
      </div>
      <input
        type="submit"
        class="btn btn-secondary text-white"
        style="width: 100%;"
        :disabled="this.$v.$invalid || messageSent || messageSending"
        :value="$t('submit-btn-txt')"
      />
    </form>
    <div v-if="messageSent" class="text-white mt-2">{{ $t("message-sent-confirmation") }}</div>
    <div v-if="messageError" class="text-danger mt-2">{{ $t("message-error") }}</div>
  </div>
</template>

<style lang="scss" scoped>
label {
  color: white;
}
</style>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import emailjs from "emailjs-com";

export default {
  data: () => {
    return {
      hover: null,
      contactName: "",
      email: "",
      message: "",
      messageSent: false,
      messageSending: false,
      messageError: false,
      valid: false
    };
  },
  methods: {
    sendMessage(e) {
      this.messageSending = true;
      var vm = this;
      emailjs
        .sendForm(
          "default_service",
          process.env.VUE_APP_MAILJS_TEMPLATE_ID,
          e.target,
          process.env.VUE_APP_MAILJS_USER_ID
        )
        .then(
          result => {
            vm.messageSending = false;
            vm.messageSent = true;
            return result;
          },
          error => {
            vm.messageSending = false;
            vm.messageError = true;
            vm.contactName = "";
            vm.email = "";
            vm.message = "";
            return error;
          }
        );
    }
  },
  validations: {
    contactName: {
      required,
      minLength: minLength(4)
    },
    email: {
      required,
      email
    },
    message: {
      required,
      minLength: minLength(4)
    }
  }
};
</script>

<i18n>
{
  "en" : {
    "title" : "Contact us",
    "name-field-label" : "Name",
    "submit-btn-txt" : "Send",
    "message-sent-confirmation" : "Your message was sent, thank you!",
    "message-error" : "Your message could not be sent, please try again.",
    "invalid-email" : "Invalid e-mail."
  },
  "fr" : {
    "title"  : "Nous contacter",
    "name-field-label" : "Nom",
    "submit-btn-txt" :"Envoyer",
    "message-sent-confirmation" : "Votre message a bien été envoyé, merci !",
    "message-error" : "Votre message n'a pas pu être envoyé, merci d'essayer à nouveau.",
    "invalid-email" : "Email invalide."
  }
}
</i18n>
